/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { apiError } from '../api/apiSlice';
import WebClient from '../../utils/web-client';

export const fetchCategories = createAsyncThunk('meta/fetchCategories', async (dispatch) => {
    try {
        const { data } = await WebClient.get('/FirmDashboard/GetItemCategories');
        return data;
    } catch (error) {
        dispatch(apiError('Unable to retrieve categories.', error));
        throw error;
    }
});

const metaSlice = createSlice({
    name: 'meta',
    initialState: {
        categories: [],
        loading: false,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.pending, (state) => { state.loading = true; });
        builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
            state.categories = payload;
            state.loading = false;
        });
    },
});

export const getMetaState = (state) => state.meta;

export const getCategories = createSelector(
    getMetaState,
    (meta) => meta.categories || [],
);

export const getCategoryById = (categoryId) => createSelector(
    getCategories,
    (categories) => categories.find((category) => category.CategoryID === categoryId),
);

export default metaSlice.reducer;
