import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const baseTheme = createTheme({
    typography: {
        fontFamily: '"Open Sans", sans-serif',
        fontWeight: 400,
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        text: {
            primary: Colors.black,
            secondary: Colors.greyDark,
        },
        primary: {
            main: Colors.blueSky,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.green,
            contrastText: Colors.white,
        },
        tertiary: {
            main: Colors.ultraViolet,
            contrastText: Colors.black,
        },
        quaternary: {
            main: Colors.tealDark,
        },
        background: {
            default: Colors.concrete1,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            fontSize: '2.4rem',
            fontWeight: 400,
            color: Colors.aubergine,
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2.8rem',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '3.0rem',
            },
        },
        h2: {
            fontSize: '2.0rem',
            fontWeight: 400,
            color: Colors.aubergine,
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2.2rem',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '2.4rem',
            },
        },
        h3: {
            fontSize: '1.7rem',
            fontWeight: 700,
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.8rem',
            },
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 700,
            color: Colors.aubergine,
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.6rem',
            },
        },
        h5: {
            fontSize: '1.3rem',
            fontWeight: 600,
            color: Colors.aubergine,
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.4rem',
            },
        },
        h6: {
            fontSize: '1.4rem',
        },
        body1: {
            fontSize: '1.4rem',
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.5rem',
            },
        },
        body2: {
            fontSize: '1.6rem',
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '1.7rem',
            },
        },
        bodyCard: {
            fontSize: '1.3rem',
            color: Colors.grey,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: 0,
                    left: 0,
                    color: Colors.aubergine,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    letterSpacing: '0.1rem',
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.dark,
                },
                colorPrimary: {
                    color: Colors.aubergine,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.5rem',
                    marginTop: 5,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: Colors.aubergine,
                    lineHeight: 1.1875,
                    fontSize: '1.5rem',

                    '&:hover': {
                        color: baseTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                },
                select: {
                    border: `1px solid ${Colors.iron}`,
                    borderRadius: '20px',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    color: Colors.grey,
                    padding: '8px 14px',
                    paddingRight: '32px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    lineHeight: '2.1rem',
                    textTransform: 'none',
                    fontSize: '1.6rem',
                    padding: '16px',
                    '&:focus-visible': {
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.primary.light,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: Colors.greyLightest,
                        color: Colors.greyLight,
                    },
                },
                outlinedPrimary: {
                    borderColor: Colors.greyLight,
                    '&:hover': {
                        backgroundColor: Colors.greyLightest,
                        borderColor: baseTheme.palette.primary.main,
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.secondary.light,
                    },
                },
                containedTertiary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {

                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: '1.3rem',
                    textDecoration: 'none',

                    '&.header-link': {
                        color: baseTheme.palette.primary.contrastText,
                        fontWeight: 600,
                        textDecoration: 'underline',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${Colors.iron}`,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    color: Colors.grey,

                    '&:hover': {
                        color: Colors.aubergine,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: '1.3rem',
                    color: Colors.aubergine,
                    fontWeight: 600,
                },
                root: {
                    '& .MuiAlert-icon': {
                        color: Colors.aubergine,
                    },
                    color: Colors.aubergine,
                },
                filledError: {
                    backgroundColor: `rgba(${Colors.rubineRGB}, 0.2)`,
                },
                filledSuccess: {
                    backgroundColor: Colors.success,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '30px',
                    borderRadius: '8px',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    padding: '2px 6px',
                    borderRadius: '5px',
                },
                label: {
                    padding: 0,
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: '10px',
                    color: Colors.aubergine,

                    '& .MuiLink-root': {
                        color: 'inherit',
                        fontSize: 'inherit',

                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                    },
                },
            },
        },
    },
});

export default theme;
