import { combineReducers } from 'redux';
import metaReducer from '../features/meta/metaSlice';
import apiReducer from '../features/api/apiSlice';
import userReducer from '../features/user/userSlice';
import authReducer from '../features/auth/authSlice';
import awardReducer from '../features/award/awardSlice';
import itemReducer from '../features/item/itemSlice';

const Reducers = () => combineReducers({
    user: userReducer,
    award: awardReducer,
    item: itemReducer,
    auth: authReducer,
    meta: metaReducer,
    api: apiReducer,
});

export default Reducers;
